<template>
  <div v-if="authUserIsCoach">
    <LoadingMessage v-if="isLoading" :message="'Fetching circuits...'"></LoadingMessage>

    <form @submit.prevent v-if="!isLoading" class="d-flex align-items-center bg-dark p-2 rounded">
      <div class="form-floating-cta-container">
        <div class="form-floating-cta">
          <fa-icon
            @click="resetFilter"
            v-if="keyword.length"
            :icon="['fas', 'times-circle']"
            role="button"
          ></fa-icon>
        </div>
        <input
          v-model.trim="keyword"
          ref="keyword"
          type="text"
          class="form-control form-control-dark"
          placeholder="Search circuit..."
        >
      </div>
      <IconButton
        @click="showModal = true"
        :icon="'plus'"
        class="btn btn-primary ms-2"
      ></IconButton>
      <IconButton
        @click="fetchItems"
        :icon="'sync-alt'"
        class="btn btn-primary ms-2"
      ></IconButton>
    </form>

    <div v-if="!isLoading" class="rounded border bg-white mt-2">
      <div class="table-responsive">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col" class="align-baseline">Circuit name</th>
              <th scope="col" class="align-baseline">Video</th>
              <th scope="col" class="align-baseline"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!filteredItems.length">
              <td colspan="3">No circuits found</td>
            </tr>
            <CircuitListItem
              @update="setItemForUpdate"
              @delete="deleteItem"
              v-for="(circuit, circuitIndex) in filteredItems"
              :key="`key-${circuit.circuitLibraryId}`"
              :circuit="circuit"
              :index="circuitIndex + 1"
            ></CircuitListItem>
          </tbody>
        </table>
      </div>
    </div>

    <portal to="modal">
      <transition name="modal">
        <CircuitModal
          @addOrUpdate="addOrUpdateItem"
          @close="hideModal"
          v-model="showModal"
          v-if="showModal"
          :circuit="selectedItem"
        ></CircuitModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'CircuitList',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    CircuitModal: () => import('./CircuitModal.vue'),
    CircuitListItem: () => import('./CircuitListItem.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
    filteredItems() {
      const { keyword } = this;
      if (keyword.length) {
        const q = keyword.toLowerCase();
        return this.items.filter((circuit) => {
          const { name, description } = circuit;
          return name.toLowerCase().indexOf(q) >= 0 || description.toLowerCase().indexOf(q) >= 0;
        });
      }
      return this.items;
    },
  },
  methods: {
    async fetchItems() {
      this.isLoading = true;
      this.keyword = '';
      try {
        const res = await httpGet('/library/list/circuit');
        this.items = res.data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    addOrUpdateItem(item) {
      const itemIndex = this.items.findIndex((i) => i.circuitLibraryId === item.circuitLibraryId);
      if (itemIndex > -1) {
        this.items.splice(itemIndex, 1, item);
      } else {
        this.items.push(item);
      }
    },
    deleteItem(itemId) {
      const itemIndex = this.items.findIndex((i) => i.circuitLibraryId === itemId);
      if (itemIndex > -1) this.items.splice(itemIndex, 1);
    },
    setItemForUpdate(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
    resetFilter() {
      this.keyword = '';
      this.$refs.keyword.focus();
    },
    hideModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      keyword: '',
      items: [],
      selectedItem: null,
    };
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style lang="scss" scoped>
th[scope="col"] {
  &:nth-child(1) {
    width: 74%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 1%;
  }
}
</style>
